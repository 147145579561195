
// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import colors from "../../assets/css/colors";
import LockCircle from "../Icons/LockCircle";
import Lock from "../Icons/Lock";
import { BYE_ROUNDS, PARTIAL_BYE_ROUNDS, isAllTrue } from "../../helpers";
import CoachToggle from "../CoachToggle";
import { below } from "../../assets/css/media";

import { Exist } from "../Exist";
import { Tooltip } from "../Form";
import ByeButton from "./button";


const LockedLabel = styled.label`
	color: #8D979C;
	font-size: 14px;
	line-height: 1.2;
	text-align: right;
	text-transform: uppercase;
	padding: 5px 0 0 5px;

	& span.short_name {
		display: none;
	}
`;

const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${({ is_classic }) => is_classic ? "padding: 6px 5px" : "padding: 0px 5px"};
	${({ is_classic }) => is_classic && `background-color: ${colors.secondary.lightGrey}`};

	> button {
		margin: 0 2px;

		:first-child {
			margin-left: 0;
		}
		:last-child {
			margin-right: 0;
		}
	}
`;

const ControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	flex: 1 1 0px;
	.tooltip-ebd{
		z-index: 96;
	}

	${({ is_classic }) => is_classic && "background-color: white"};
	${({ is_classic }) => is_classic && "padding: 10px"};
	${({ is_classic }) => is_classic && "border-radius: 2px"};
	${({ has_coach, is_classic }) => 
		(!has_coach && is_classic) && `border: 1px solid ${colors.coach};`}
	width: ${({ is_classic }) => is_classic ? "300px" : "235px" };
	${({is_classic}) => !is_classic && "height: 40px"};
  ${below.phone`
    ${({ is_classic }) => !is_classic && `
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: 40px;
	  gap: 10px;
	  width: 100% !important;
    `}

  `}
`;

const Label = styled.div`
	font-size: 12px;
	color: ${colors.coach};
	font-family: SourceSansPro;
	font-weight: 600;
`;

type Props = {
	selected: number[],
	active: boolean,
	has_coach: boolean,
	bye_rounds?: number[],
	setActive: Function,
	toggleRound: Function,
	showFantasyCoachModal: Function,
	is_classic?: boolean,
	partial_bye_rounds?: number[],
	is_partial_or_standard?: string
}



export const PartialByeDetectorControls = ({ 
	has_coach = false,
	active = true,
	selected =  [],
	bye_rounds = BYE_ROUNDS,
	setActive,
	toggleRound,
	showFantasyCoachModal,
	is_classic = true,
	partial_bye_rounds = PARTIAL_BYE_ROUNDS,
	is_partial_or_standard
}: Props) => {
	return <ControlsWrapper key="early-bye" has_coach={has_coach} is_classic={is_classic} >
		{has_coach && is_classic ? <CoachToggle
			label="Early Bye Detector"
			short_label="Early Byes"
			is_checked={isAllTrue([
				active, 
				is_partial_or_standard === "partial"
			])}
			name="early-bye-detector-toggle"
			onChange={setActive}
		/> : is_classic ? <FcLink onClick={showFantasyCoachModal}>
			<Lock size={1.5}/>
			<LockedLabel>
				<span className="long_name">Early Bye Detector</span>
				<span className="short_name">Early Byes</span>
			</LockedLabel> 
		</FcLink>
			:  <React.Fragment></React.Fragment>
		}
		{(!is_classic && !has_coach) && <FcLink onClick={showFantasyCoachModal}>
			<LockCircle size={1}/>
		</FcLink>}
		{!is_classic && <Label>
			Early Bye Detector
		</Label>}
		<Tooltip className="tooltip-ebd" title="Early Bye Detector"  description="Teams that play in the Opening Round will have byes split across Rounds 2, 3 & 4. During these rounds, only your best 18 player scores will count, just like in the regular bye rounds. You will NOT receive extra trades during these early byes."/>
		<Exist when={is_classic}>
			<ButtonsWrapper is_classic={is_classic}>
				{ _.map(partial_bye_rounds, round => <ByeButton 
					round={round} 
					selected={_.includes(selected, round)} 
					disabled={!active}
					clickable
					toggleRound={toggleRound}
					key={round}
					is_classic={is_classic}
				/>)}
			</ButtonsWrapper>


		</Exist>
		<Exist when={!is_classic}>
			<ButtonsWrapper is_classic={is_classic}>
				{ _.map(partial_bye_rounds, round => <ByeButton 
					round={round} 
					selected={_.includes(selected, round)} 
					clickable
					disabled={!has_coach}
					toggleRound={toggleRound}
					key={round}
					is_classic={is_classic}
				/>)}
			</ButtonsWrapper>
		</Exist>
		
	</ControlsWrapper>;
};

export default PartialByeDetectorControls;