//@flow
import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import TeamUserAvatar from "../Team/TeamUserAvatar";
import colors from "../../assets/css/colors";
import type {TTeam} from "../../modules/types";
import ArrowDown from "../Icons/ArrowDown";
import { default_transition } from "../../assets/css/vars";
import ArrowUp from "../Icons/ArrowUp";
import { getShortName } from "../../helpers/league";
import { TeamShare } from "../TeamShare";
import { isTeamComplete } from "../../helpers";

const BS_SIZE = "2px";
const TEAM_HEIGHT = 30;

const TeamName = styled.h1`
	font-weight: 500;
	color: ${colors.primary.primary};
	font-size: 32px;
	padding-top: 4px;
`;
const DropDownTeamName = styled.div`
	font-size: 12px;
	color: ${colors.primary.primary};
	padding-left: 5px;
	user-select: none;
`;
const DropDownUserName = styled.div`
	font-size: 10px;
	color: ${colors.secondary.paleGrey};
	padding-left: 5px;
	padding-top: 2px;
	user-select: none;
`;
const DropDownTeam = styled(Link)`
	height: ${TEAM_HEIGHT}px;
	border-bottom: 1px solid ${colors.secondary.lightGrey};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	opacity: 1;
	transition: opacity 0.2s ease-out;

	> div {
		opacity: 1;
		transition: opacity 0.2s ease-out;
	}

	:hover ${DropDownTeamName} {
		text-decoration: underline;
	}
`;
const DropDown = styled.div`
	position: absolute;
	top: 100%;
	left: 48px;
	width: calc(100% - 48px);
	box-shadow: ${BS_SIZE} ${BS_SIZE} ${BS_SIZE} rgba(0, 0, 0, 0.1), 
		-${BS_SIZE} ${BS_SIZE} ${BS_SIZE} rgba(0, 0, 0, 0.1);

	border-radius: 0 0 2px 2px;
	z-index: 10;
	background-color: ${colors.form.base};
	transition: all 0.2s ease-out;

	max-height: 325px;
	overflow: scroll;


	height: ${({ teams, opened }) => opened ? teams * (TEAM_HEIGHT + 1) : 0}px;
	${({ opened }) => !opened && css`
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		${DropDownTeam} {
			opacity: 0;
			height: 0;
			border-bottom: none;
			> div {
				opacity: 0;
				font-size: 0;
			}
		}
	`}
`;
const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	height: 30px;
	width: 30px;
	padding: 5px;
	box-sizing: border-box;
	border-radius: 50%;
	cursor: pointer;
	${default_transition};

	> svg {
		fill: ${colors.primary.primary};
		${default_transition};
		pointer-events: none;
	}
	&:hover {
		background-color: ${colors.form.base};
		> svg {
			fill: ${colors.primary.accent};
		}
	}
`;
const TeamTitleWrapper = styled.div`
	display: flex;
	width: auto;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	margin-bottom: 10px;

	> div:first-child {
		height: 40px;
		width: 40px;
		margin-right: 10px;
	}
`;


type Props = {
	team: TTeam,
	league_teams: Array<Object>,
	user?: Object,
	is_classic: boolean,
	league_id: string,
	share_round_id?: number,
	className?: string,
	is_scheduled?:boolean,
}

type State = {
	opened: boolean,
};

export class TeamTitle extends React.Component<Props, State> {
	static defaultProps = {
		league_teams: [],
		is_classic: true,
		league_id: "",
		is_scheduled:true
	};
	constructor() {
		super();
		_.bindAll(this, [
			"setDropDownOpened",
			"toggleDropDown",
			"handleClick"
		]);
		this.openDropDown = _.bind(this.setDropDownOpened, this, true);
		this.closeDropDown = _.bind(this.setDropDownOpened, this, false);
	}
	state = {
		opened: false
	};
	componentDidMount() {
		document.addEventListener("click", this.handleClick);
	}
	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick);
	}
	handleClick(e: any) {
		if (
			this.node !== null &&
			!_.isUndefined(this.node) &&
			this.node.contains(e.target)
		) {
			return;
		}
		this.closeDropDown();
	}

	openDropDown: Function;
	closeDropDown: Function;
	node: any;

	setDropDownOpened(opened: boolean) {
		this.setState({
			opened
		});
	}

	toggleDropDown(e: any) {
		e.stopPropagation();
		this.setState(state => ({
			opened: !state.opened
		}));

		return false;
	}

	getTeamLink(team: TTeam) {
		const { user, is_classic, league_id } = this.props;

		const user_id = _.get(user, "id");

		const prefix = is_classic ? "/classic" : `/draft/league/${league_id}`;

		if (team.user_id === user_id) {
			return `${prefix}/team`;
		}

		return `${prefix}/team${is_classic ? "/user" : ""}/${team.id}`;
	}

	renderDropDown() {
		const { league_teams, team } = this.props;
		const { opened } = this.state;
		const drop_down_teams = _.reject(league_teams, { id: team.id });
		return <React.Fragment>
			<IconWrapper
				onClick={this.toggleDropDown}
			>
				{opened ? <ArrowUp /> : <ArrowDown  />}
			</IconWrapper>
			<DropDown opened={opened} teams={_.size(league_teams) - 1}>
				{_.map(drop_down_teams, ladder_team => {
					return <DropDownTeam
						to={this.getTeamLink(ladder_team)}
						onClick={this.closeDropDown}
						key={ladder_team.id}
					>
						<DropDownTeamName>{ladder_team.name}</DropDownTeamName>
						<DropDownUserName>{getShortName(ladder_team)}</DropDownUserName>
					</DropDownTeam>;
				})}
			</DropDown>
		</React.Fragment>;
	}
	render() {
		const { team, share_round_id, user, className } = this.props;
		const user_id = _.get(team, "user_id");
		const avatar_version = _.get(user, "avatar_version", 0);
		const shareRoundHandled = share_round_id ? share_round_id : 0;
		const lineup = team.lineup;
		const isCompleteOnce = team.complete_first_time;
		const isUtilityFull = _.get(team, 'lineup.bench.utility.id');
		const isTeamValidComplete = isTeamComplete({lineup, complete_first_time: isCompleteOnce}) 
			&& Boolean(lineup) && isUtilityFull;

		return (
			<TeamTitleWrapper className={className} innerRef={i => this.node = i}>
				<TeamUserAvatar
					user_id={user_id}
					firstname={_.get(user, "firstname", "")}
					lastname={_.get(user, "lastname", "")}
					avatar_version={avatar_version}
				/>
				<TeamName>{team.name}</TeamName>
				{/* {!_.isEmpty(league_teams) && this.renderDropDown()} */}

				<TeamShare 
					round_id={shareRoundHandled}
					isTeamComplete={isTeamValidComplete}
					team_id={team.id} />

			</TeamTitleWrapper>
		);
	}
}

export default TeamTitle;