// @flow
import React from "react";
import styled from "styled-components";
// import toyata from "../../../assets/images/toyota-black-logo.png";

const ToyatoTermsWrap = styled.div`
  display:flex;
  margin-top: 10px;
`;

// const ToyatoTermsImgWrap = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
// `;

const ToyatoTermsText = styled.div`
  font-family: SourceSansPro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align:left;
  a {
    font-weight: 600;
    color:rgb(29, 64, 115);
  }
`;


export const ToyatoTerms = () => (
	<ToyatoTermsWrap>
		<ToyatoTermsText>I’m interested in hearing from AFL and their partners</ToyatoTermsText>
		{/* <ToyatoTermsImgWrap>
			<img src={toyata} alt="Toyata" />
		</ToyatoTermsImgWrap> */}
	</ToyatoTermsWrap>
);