import React, { ReactNode } from "react";
import styled from "styled-components";

import { below } from "../../assets/css/media";

import logo from "../../assets/images/logos/svgs/afl-ver-cen-black.svg";
import HomeBackground from "../../assets/images/background/home-bg-desktop.webp";
import HomeBackgroundMobile from "../../assets/images/background/home-bg-mobile.webp";
// import winHilux from "../../assets/images/win-hilux.webp";
// import homeHilux from "../../assets/images/home-hilux2024.png";
// import AFLiDModal from "../../pages/login/aflIDModal";
import { ScreenReaderOnly } from "./../ScreenReaderOnly";
import { OneColumnLayout } from "./../OneColumnLayout";
import { Footer } from "./../Footer";

type IProps = {
	children: ReactNode;
	title: string;
}

const LoginWrapper = styled.div`
	background-color: #001431;
	background-image: url(${HomeBackground});
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
	padding: 5px 0 80px;
	min-height: calc(100vh - 119px);

  	// @media (min-width: 1550px){
	// 	background-size: 1750px auto;
	// 	background-position: top -100px center;
	// }
	${below.desktop`
		background-image: url(${HomeBackgroundMobile});
		background-size: cover;
		background-position: left;
		min-height: 630px;
		padding: 5px 0 20px;
	`};
`;

const LogoContainer = styled.div`
	display:flex;
	width: 100%;
	margin: auto;
	height: 260px;
	align-items: center;
	justify-content: center;
	max-width: 695px;
	    ${below.phone`
      height: 200px;
    `}
		 img {
    width: 100%;
    max-width: 340px;
    ${below.phone`
      max-width: 240px;
    `}
  }
`;

const OneColumnLayoutStyled = styled(OneColumnLayout)`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
  ${below.phone`
    margin: 0px auto 50px;
  `}
`;

export const Landing: React.FC<IProps> = ({ title = "", children }) => (
	<React.Fragment>
		<LoginWrapper id="login-root">
			<ScreenReaderOnly>
				<h1>{title}</h1>
			</ScreenReaderOnly>
			<OneColumnLayoutStyled>
				<LogoContainer>
					<img src={logo} alt="AFL Fantasy Logo" />
				</LogoContainer>
				{children}
			</OneColumnLayoutStyled >
		</LoginWrapper>
		{/* <AFLiDModal /> */}
		<Footer />
	</React.Fragment>
);

export default Landing;