import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import bindAll from "lodash/bindAll";
import styled from "styled-components";

import { get } from "lodash";
import { below } from "../assets/css/media";
import colors from "../assets/css/colors";
import isAuthenticated from "../utils/auth/isAuthenticated";
import { clearLoginErrors, forgotPassword, login, setAflIdModalStatus } from "../modules/actions";
import { TUser } from "../modules/types";
import { TextModal } from "../components";
import { Landing } from "../components/Landing";
import { isChangePasswordSuccess } from "../modules/selectors";
import { getLoginLink, isAllTrue } from "../helpers";
import { getAflIdModalStatus } from "../modules/selectors/aflIdModal";
import { AFLIdModalStatus, TAFLIdModalTypes } from "../modules/types/store/aflIdModalReducer";
import { dataLayer } from "../utils";
import logoClassic from "../assets/images/logos/svgs/afl-classic-ver-cen-white.svg";
import logoDraft from "../assets/images/logos/svgs/afl-draft-ver-cen-white.svg";
import ResetPasswordModal from "./login/resetPasswordModal";


const LoginTitle = styled.h2`
	color: #000000;
	// font-size: 52px;
	font-size: 28px;
	line-height: 1.3;
	font-weight: 700;
	max-width: 480px;
	margin: 10px .5em 26px .5em;
  ${below.phone`
	display: none;
  `}
`;

const FantasyCardLinkContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 20px;
	padding: 20px;
	max-width: 1316px; 
	align-items: center;
	justify-content: center;
	${below.phone`
	flex-direction: column;
  `}
	`;

const FantasyCardLink = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
	gap: 20px;
	background: ${colors.primary.main};
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	`;

const FantasyLogo = styled.img`
    width: 100%;
    max-width: 184px;
`;

const FantasyPlayButtonLink = styled.button`
	background: ${props => props.disabled ? "#DEEDF4" : "#FEFEFE"};
	border-radius: 100px;
	border: none;
	color: ${props => props.disabled ? "#6A758B" : "#12377B"};
	cursor: ${props => props.disabled ? "not-allowed !important" : "pointer"};
	point
	font-size: 16px;
	font-weight: 700;
	padding: 0 12px;
	min-height: 48px;
	width: 100%;
	&:not([disabled]):hover{
		background-color: ${colors.secondary.lightGrey};
	}

`;

type Props = {
	is_authorized: boolean,
	user: TUser,
	login: typeof login,
	clearLoginErrors: typeof clearLoginErrors,
	forgotPassword: typeof forgotPassword,
	show_login?: boolean,
	match: { params: { code: string } },
	setAflIdModalStatus: TAFLIdModalTypes,
	changePasswordSuccess: boolean
};

type State = {
	show_sign_up: boolean,
	click_count: number
}

class LoginPage extends React.Component<Props, State> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleErrorModalClose",
			"showResetPassword",
			"hideResetPassword",
			"handleSubmitResetPassword",
			"handleClick",
			"infoAFLidModal",
			"onSelectDraft"
		]);
	}

	state = {
		show_sign_up: false,
		click_count: 0
	};

	componentDidMount() {
		document.getElementById("menu-wrapper").click();
	}

	get error_modal() {
		const { user } = this.props;

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={user.login.error || user.forgot_password.error}
			/>
		);
	}

	showResetPassword(event) {
		event.preventDefault();
		this.setState({ show_sign_up: true });
	}

	hideResetPassword() {
		this.setState({
			show_sign_up: false,
		});

		const { clearLoginErrors } = this.props;
		clearLoginErrors();
	}

	get sign_up_module() {
		const { user } = this.props;

		return (
			<ResetPasswordModal
				onSubmit={this.handleSubmitResetPassword}
				closeClick={this.hideResetPassword}
				confirmClick={this.handleSubmitResetPassword}
				sent_to_reset_password={user.forgot_password.success}
			/>
		);
	}

	handleErrorModalClose() {
		const { clearLoginErrors } = this.props;
		clearLoginErrors();
	}

	handleSubmit(values) {
		const { login } = this.props;
		login(values);
	}

	handleSubmitResetPassword(values) {
		this.props.forgotPassword(values);
		this.setState({ sent_to_reset_password: true });
	}

	onSelectGame() {
		dataLayer({
			'event': 'login-click'
		});
		localStorage.setItem('original-click', 'login');
		window.location.href = getLoginLink();
	};

	onSelectDraft() {
		window.open("https://fantasydraft.afl.com.au/?utm_source=aflfantasy&utm_medium=web&utm_id=draft-launch&utm_content=landing", "_blank");
	};

	// onSignUp() {
	// 	dataLayer({
	// 		'event': 'sign-up-click'
	// 	});
	// 	localStorage.setItem('original-click', 'sign-up');
	// 	window.location.href = getLoginLink();
	// }


	handleClick() {
		this.setState({
			click_count: this.state.click_count + 1
		});
	}

	infoAFLidModal() {
		this.props.setAflIdModalStatus(AFLIdModalStatus.Info);
	}

	render() {
		const { user, is_authorized, changePasswordSuccess } = this.props;
		const { show_sign_up } = this.state;
		const userRecovered = get(user.data, 'recovered', 0);
		const isChangePasswordFlow = localStorage.getItem('change_password_flow') === "true";
		if (isAllTrue([is_authorized, changePasswordSuccess, isChangePasswordFlow])) {
			window.location.href = getLoginLink();
			localStorage.setItem('change_password_flow', 'false');
		};
		if (is_authorized && userRecovered === 1) {
			return <Redirect to="/game-selection" />;
		}
		return (
			<React.Fragment>
				<Landing title='Login to Play AFL Fantasy'>
					<LoginTitle onClick={this.handleClick}>
						Are you ready for 2025 <br></br> AFL Fantasy season
					</LoginTitle>
					<FantasyCardLinkContainer>
						<FantasyCardLink>
							<FantasyLogo src={logoClassic} alt="AFL Fantasy Classic Logo"></FantasyLogo>
							<FantasyPlayButtonLink onClick={this.onSelectGame}>
								Play Now
							</FantasyPlayButtonLink>
						</FantasyCardLink>
						<FantasyCardLink>
							<FantasyLogo src={logoDraft} alt="AFL Fantasy Draft Logo"></FantasyLogo>
							<FantasyPlayButtonLink onClick={this.onSelectDraft}>
								Play Now
							</FantasyPlayButtonLink>
						</FantasyCardLink>
					</FantasyCardLinkContainer>
				</Landing>
				{show_sign_up ? this.sign_up_module : null}
				{user.login.error || user.forgot_password.error ? this.error_modal : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	const aflIdModalStatus = getAflIdModalStatus(state);
	return {
		is_authorized: isAuthenticated(),
		user: state.user,
		aflIdModalStatus,
		changePasswordSuccess: isChangePasswordSuccess(state)
	};
};

const mapDispatchToProps = {
	login,
	clearLoginErrors,
	forgotPassword,
	setAflIdModalStatus: setAflIdModalStatus,

};

export const Login = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginPage));

export default Login;