// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../modules/actions";
import { showModalForPlayerId } from "../../../../modules/actions";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";
import { FavButton, PlayerCost, PlayerStatusIcon, StatusButton } from "../../../../components";
import Star from "../../../../components/Icons/Star";
import type { TPlayer } from "../../../../modules/types";
import { PoolPlayerStatus } from "./poolPlayerStatus";
const PlayerDetailsInfo = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	height: 60px;
`;

const WrapperPlayerInfo = styled.div`
	display: flex;
	margin: ${({ short_opponent }) => short_opponent ? "0 .5em 0 0" : "0 .5em"};
	color: ${colors.primary.darkGrey};
	font-size: 12px;
	width: 200px;
	${({ short_opponent }) => short_opponent ? "overflow: hidden;" : ""}
	${({ reverse }) => reverse ? "flex-direction: row-reverse;" : ""}
	
	${PoolPlayerStatus}{
		height: 70px;
		${below.desktop`
			height: 60px;
		`}
	}
	${PlayerDetailsInfo}{
		height: 70px;
		${below.desktop`
			height: 60px;
		`}
	}
	${({ is_mobile }) => is_mobile && css`
		${PoolPlayerStatus} {
			height: 50px;
			:after, :before {
				height: 40px;
			}
		}
	`}
`;



const PlayerName = styled.span`
	color: ${colors.primary.primary};
	font-weight: 700;
	font-size: 14px;
	cursor: pointer;
	text-align: left;
	margin-bottom: 1px;
	padding-bottom: 4px;
	line-height: 1;

	:hover {
		text-decoration: underline;
	}

	max-width: 95px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${below.phone`
		max-width: 65px;
		font-size: 11px;
	`}
`;

const PlayerAvatar = styled.div`
	height: 60px;
	width: 60px;
	flex-shrink: 0;
	object-fit: contain;
	cursor: pointer;
	background-position: center;
    background-size: cover;
    object-fit: contain;
	${({ short_opponent }) => short_opponent ? "margin-left: -20px;" : ""}
	${({ reverse }) => reverse ? "margin-left: .5em" : ""}

	img {
		max-width: 100%;
		height: 100%;
	}
`;

const Positions = styled.span`
	span.short {
		display: none;
	}

	${below.phone`
		span.long {
			display: none;
		}
		span.short {
			display: inline;
		}
	`}
`;

const PlayerDetails = styled.span`
	color: ${colors.primary.primary};
	font-weight: 400;
	font-size: 12px;
	text-align: left;
	line-height: 1;
	text-transform: uppercase;

	${below.desktop`
		font-size: 10px;
	`}

	${below.phone`
		font-size: 9px;
		letter-spacing: -0.1px;
	`}
`;

const PlayerCostDetails = styled.div`
	margin-top: 6px;
	display: flex;
	align-items: flex-end;

	> p:first-child {
		margin-bottom: 0;
	}

	${below.phone`
		> p {
			line-height: 16px;
			font-size: 10px;
			height: 14px;
		}
	`}
`;

const StatusButtonStyled = styled(StatusButton)`
	height: 20px;
	width: 20px;
	margin: 2.5px 0;
	svg {
		height: 16px;
		width: 16px;
	}

	${below.phone`
		height: 16px;
		width: 16px;

		svg {
			height: 12px;
			width: 12px;
		}
	`}
`;

const FavButtonStyled = styled(FavButton)`
	height: 20px;
	width: 20px;
	margin: 2.5px 0;
	color: #fff;
	font-size: 12px;

	${below.phone`
		height: 16px;
		width: 16px;

		svg {
			height: 12px;
			width: 12px;
		}
	`}
`;

const PlayerNameInfoWrapper = styled.div`
	white-space: nowrap;
	display: flex;
	align-items: center;
	
	${PlayerName} {
		margin-right: 5px;
		margin-bottom: 0;
	}
`;


type Props = {
	player: TPlayer,
	short_opponent?: boolean,
	reverse?: boolean,
	is_mobile?: boolean,
	with_opponent?: boolean,
	from_pool?: boolean,
	showModalForPlayerId: Function,
	postRemoveFromFavourites: typeof actions.postRemoveFromFavourites,
	postAddToFavourites: typeof actions.postAddToFavourites,
	showModalForPlayerId: typeof actions.showModalForPlayerId,
	player_is_favourite: boolean
};

class PoolPlayerInfoContainer extends React.Component<Props> {
	static defaultProps = {
		is_mobile: false,
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"openPlayerPopUp",
			"toggleFavs",
		]);
	}

	shouldComponentUpdate(nextProps: Props): boolean {
		return !_.eq(this.props.player_is_favourite, nextProps.player_is_favourite);
	}

	openPlayerPopUp() {
		const { showModalForPlayerId, player } = this.props;
		showModalForPlayerId(_.get(player, "id"));
	}

	toggleFavs() {
		const {
			player_is_favourite,
			postRemoveFromFavourites,
			postAddToFavourites,
			player,
		} = this.props;

		player_is_favourite ? postRemoveFromFavourites(player.id) :	postAddToFavourites(player.id);
	}

	renderPlayerCost() {
		const { player } = this.props;
		let round_diff = _.get(player, "stats.cost_round_diff", 0);
		let season_diff = _.get(player, "stats.cost_season_diff", 0);

		const has_one = Boolean(
			(round_diff || season_diff)
			&& (round_diff !== "-" || season_diff !== "-")
		);

		return (
			<PlayerCostDetails>
				<PlayerCost cost={player.cost}/>
				{has_one && (
					<React.Fragment>
						<PlayerCost
							cost={round_diff}
							is_diff
						/>
						<PlayerCost
							cost={season_diff}
							is_diff
						/>
					</React.Fragment>
				)}
			</PlayerCostDetails>
		);
	}

	getPositions() {
		const { player } = this.props;

		const positions = _.get(player, "positions_name", []);

		return <Positions>
			<span className="long">{ positions.join(" / ") }</span>
			<span className="short">{
				_.map(positions,
					pos => _.size(positions) > 1 ? pos.charAt(0) : pos
				).join("/")
			}</span>
		</Positions>;
	}

	render(){
		const { player } = this.props;

		if(_.isEmpty(player)) {
			return null;
		}

		const {
			short_opponent,
			reverse,
			player_is_favourite,
			is_mobile,
			...rest
		} = this.props;

		const
			NameWrapper = is_mobile ? PlayerNameInfoWrapper : React.Fragment,
			player_name = `${player.first_name[0]}. ${player.last_name}`;
		const player_img = process.env.REACT_APP_PLAYERS_PATH + player.id + "_450.png" || "";
		const player_img_webp = process.env.REACT_APP_PLAYERS_PATH + player.id + "_450.webp" || "";
		return (
			// $FlowFixMe
			<WrapperPlayerInfo
				is_mobile={is_mobile}
				short_opponent={short_opponent}
				reverse={reverse}
				{...rest}
			>
				<PlayerAvatar
					short_opponent={short_opponent}
					reverse={reverse}
					className="player-avatar"
					onClick={this.openPlayerPopUp}
				>
					<picture>
						<source srcSet={player_img_webp} type="image/webp" />
						<source srcSet={player_img} type="image/png" />
						<img src={player_img_webp} alt={player_name} />
					</picture>

					{/*<img*/}
					{/*	src={`${process.env.REACT_APP_PLAYERS_PATH + player.id}.png`}*/}
					{/*	alt={player_name}*/}
					{/*/>*/}
				</PlayerAvatar>
				<PoolPlayerStatus>
					<StatusButtonStyled status={player.status} is_bye={player.is_bye}>
						<PlayerStatusIcon status={player.status} is_bye={player.is_bye} />
					</StatusButtonStyled>
					<FavButtonStyled active={player_is_favourite} onClick={this.toggleFavs}>
						<Star />
					</FavButtonStyled>
				</PoolPlayerStatus>
				<PlayerDetailsInfo>
					<NameWrapper>
						<PlayerName
							status={player.status}
							onClick={this.openPlayerPopUp}
							className="player-name"
						>
							{player_name}
						</PlayerName>
						<PlayerDetails className="player-details">
							{this.getPositions()}
							{" "}
							{_.get(player.competition, "symbol")}
							{" "}
							{_.get(player.competition, "opponent.short_name")}
						</PlayerDetails>
					</NameWrapper>
					{this.renderPlayerCost()}
				</PlayerDetailsInfo>
			</WrapperPlayerInfo>
		);
	}
}

const mapStateToProps = (state, props) => ({
	favs: state.players.favourites,
	player_is_favourite: state.players.favourites.includes(props.player && props.player.id)
});

const mapDispatchToProps = {
	showModalForPlayerId,
	postRemoveFromFavourites: actions.postRemoveFromFavourites,
	postAddToFavourites: actions.postAddToFavourites,
};

export const PoolPlayerInfo = connect(
	mapStateToProps,
	mapDispatchToProps
)(PoolPlayerInfoContainer);

export default PoolPlayerInfo;