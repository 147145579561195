// @flow
import {
	TDraftTeamNamePayload,
	TAFLiDAuthCode,
	TAFLiDLogin,
	TAFLiDLinkAccount,
	TAFLiDCheckCredentials,
	TCreateUser,
	TLinkNewAccount,
	TUFLogin
} from "../../modules/types/index";
import { getSessionId, logout } from "../auth";
import { getLogoutParams } from "../../helpers";
import createHttpClient from "./createHttpClient";
import * as teamsClassic from "./teamsClassic";


const USE_LOCAL_API = false;
const LOCAL_API_URI = "https://fantasy.afl.loc:4000/";
const AFL_ID_API_PATH = USE_LOCAL_API ? LOCAL_API_URI:"";

const apiClient = createHttpClient({
	baseApiUrl: process.env.REACT_APP_API_URL,
	baseJSONUrl: process.env.REACT_APP_JSON_URL,
	getSessionId,
	logout,
});

const apiClassicClient = createHttpClient({
	baseApiUrl: process.env.REACT_APP_CLASSIC_API_URL,
	baseJSONUrl: process.env.REACT_APP_JSON_URL,
	getSessionId,
	logout,
});
const league_draft_prefix_path = "leagues_classic";

const aflIDClient = createHttpClient({
	baseApiUrl: process.env.REACT_APP_SSO_URL,
});


type TLogoutParams = {
	id_token: string;
}

export const Api = {
	leaguesDraft: {
		create: (params: any) => 
			apiClient.Private.post_multipart(`${league_draft_prefix_path}/create`, params),
		update: (params: any)  => 
			apiClient.Private.post_multipart(`${league_draft_prefix_path}/update`, params),
		create_uf: (params: any) => 
			apiClient.Private.post_multipart(`${league_draft_prefix_path}/create_uf`, params),
		uploadAvatar: (params: any)  =>
			apiClient.Private.post(`${league_draft_prefix_path}/upload_avatar`, params),
		show: (params: any)  => apiClient.Private.get(`${league_draft_prefix_path}/show`, params),
		showMy: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/show_my`, params),
		showTeams: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/show_teams`, params),
		remove: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/remove`, params),
		regenerateList: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/regenerate_list`, params),
		regenerateShow: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/regenerate_show`, params),
		regenerateShowUF: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/regenerate_show_uf`, params),
		inviteTo: (params: any)  => 
			apiClient.Private.post(`${league_draft_prefix_path}/invite_to`, params),
		showToJoin: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/show_to_join`, params),
		joinTo: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/join_to`, params),
		leave: (params: any)  => apiClient.Private.get(`${league_draft_prefix_path}/leave`, params),
		removeTeam: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/remove_team`, params),
		customDraftOrder: (params: any)  =>
			apiClient.Private.post(`${league_draft_prefix_path}/custom_draft_order`, params),
		customScoring: (params: any)  =>
			apiClient.Private.post_multipart(`${league_draft_prefix_path}/custom_scoring`, params),
		showOrder: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/show_order`, params),
		showCustomFullDraftOrder: (params: any) => apiClient.Private.get(
			`${league_draft_prefix_path}/show_custom_full_draft_order`
			, params),
		saveCustomFullDraftOrder: (params: any)  => apiClient.Private.post(
			`${league_draft_prefix_path}/save_custom_full_draft_order`
			, params),
		waiverRequests: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/waiver_requests`, params),
		waiverOrder: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/waiver_order`, params),
		waiverList: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/waiver_list`, params),
		waiverFree: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/waiver_free`, params),
		waiverHistory: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/waiver_history`, params),
		ladder: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/ladder`, params),
		myList: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/my_list`, params),
		rankings: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/rankings`, params),
		rosters: (params: any)  =>	
			apiClient.Private.get(`${league_draft_prefix_path}/rosters`, params),
		selectedPlayers: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/selected_players`, params),
		draftHistory: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/draft_history`, params),
		transactionHistory: (params: any)  =>
			apiClient.Private.get(`${league_draft_prefix_path}/transaction_history`, params),
		server_time: () => apiClient.Private.get(`${league_draft_prefix_path}/server_time`),
		subscribe: (params: any)  => apiClient.Private.post("payment/league_subscribe", params),
		changeCoach: (params: any)  => 
			apiClient.Private.post(`${league_draft_prefix_path}/change_team_coach`, params),
		teamOverrideScores: (params: any)  => 
			apiClient.Private.get(`${league_draft_prefix_path}/team_override_scores`, params),
		updateTeamScores: (params: any)  =>
			apiClient.Private.post(`${league_draft_prefix_path}/update_team_scores`, params),
		saveKeeperDraftPicks: (params: any)  => 
			apiClient.Private.post(`${league_draft_prefix_path}/save_keeper_draft_picks`, params),
		waiver_add_drop_players: (params: any) => 
			apiClient.Private.post("teams_draft/waiver_add_and_drop_players", params)
	},
	players: {
		fetch: () => apiClient.JSON.get('players.json'),
		coach: () => apiClient.JSON.get('coach/players.json'),
		fetch_past_year: (params: any) => apiClient.JSON.get(`archive/${params.year}/players.json`),
		fetch_past_player_matches: (params: any)  => apiClient.JSON.get(
			`archive/${params.year}/stats/players/${params.player_id}.json`
		),
		fetch_matches: (params: any)  => 
			apiClient.JSON.get(`stats/players/${params.player_id}.json`),
		fetch_all_matches: (params: any)  => apiClient.JSON.get(`stats/${params.round_id}.json`),
		favourites: () => apiClient.Private.get('players/favourites'),
		add_to_favourites: (params: any) => 
			apiClient.Private.get('players/add_to_favourites', params),
		remove_from_favourites: (params: any)  => 
			apiClient.Private.get('players/remove_from_favourites', params),
		fetch_all_stats: () => apiClient.JSON.get('stats/all.json'),
		fetch_all_custom_stats: (params: any)  => 
			apiClient.JSON.get(`draft/${params.league_id}/stats.json`, params),
		fetch_all_custom_coach_stats: (params: any)  => 
			apiClient.JSON.get(`draft/${params.league_id}/coach.json`, params),
		fetch_players_venue_stats: () => apiClient.JSON.get('stats/players_venues_stats.json'),
		fetch_player_venue_stats_draft: (params: any)  => 
			apiClient.JSON.get(`draft/${params.league_id}/players_venues.json`),
		fetch_players_opposition_stats: () => 
			apiClient.JSON.get('stats/players_opponents_stats.json'),
		fetch_players_opposition_stats_draft: (params: any)  => 
			apiClient.JSON.get(`draft/${params.league_id}/players_opponents.json`),
	},
	teamsDraft: {
		show: (params: any) => apiClient.Private.get("teams_draft/show", params),
		show_my: (params: any)  => apiClient.Private.get("teams_draft/show_my", params),
		update_list: (params: any)  => apiClient.Private.post("teams_draft/update_list", params),
		show_list: (params: any)  => apiClient.Private.get("teams_draft/show_list", params),
		reset_list: (params: any)  => apiClient.Private.post("teams_draft/reset_list", params),
		pick_player: (params: any)  => apiClient.Private.post("teams_draft/pick_player", params),
		toggle_auto_pick: (params: any)  =>
			apiClient.Private.get("teams_draft/toggle_auto_pick", params),
		update: (params: any)  => apiClient.Private.post("teams_draft/update", params),
		commissioner_rade: (params: any) => apiClient.Private.post(
			"teams_draft/commissioner_trade", params
		),
		snapshot: (params: any)  => apiClient.Private.get("teams_draft/snapshot", params),
		lineups: (params: any)  => apiClient.Private.get("teams_draft/lineups", params),
		request_waiver_trade: (params: any)  =>
			apiClient.Private.post("teams_draft/request_waiver_trade", params),
		
		make_trade: (params: any)  => apiClient.Private.get("teams_draft/make_trade", params),
		trades: (params: any)  => apiClient.Private.get("teams_draft/trades", params),
		reject_trade: (params: any)  => apiClient.Private.get("teams_draft/reject_trade", params),
		accept_trade: (params: any)  => apiClient.Private.get("teams_draft/accept_trade", params),
		remove_player: (params: any)  => apiClient.Private.get("teams_draft/remove_player", params),
		block_trade: (params: any)  => apiClient.Private.get("teams_draft/block_trade", params),
		approve_trade: (params: any)  => apiClient.Private.get("teams_draft/approve_trade", params),
		remove_waiver_trade: (params: any)  =>
			apiClient.Private.get("teams_draft/remove_waiver_trade", params),
		offer_trades: (params: any)  => apiClient.Private.get("teams_draft/offer_trades", params),
		manage_order_waiver_trade: (params: any)  =>
			apiClient.Private.post("teams_draft/manage_order_waiver_trade", params),
		select_keeper: (params: any)  =>
			apiClient.Private.post("teams_draft/select_keeper", params),
		remove_keeper: (params: any)  =>
			apiClient.Private.post("teams_draft/remove_keeper", params),
	},
	countries: () => apiClient.JSON.get("countries.json"),
	notification_bar: () => apiClient.JSON.get("notification-bar.json"),
	notification_bar_draft: () => apiClient.JSON.get("notification-bar-draft.json"),
	winners: () => apiClient.JSON.get("classic_winners.json"),
	faqs: () => apiClient.JSON.get("zendesk-faq.json"),
	off_season_rankings: () => apiClient.JSON.get("winners/classic.json"),
	off_season_players: () => apiClient.JSON.get("players.json"),
	player_news: () => apiClient.JSON.get("news/2022/player-preview.json"),
	rounds: {
		fetch: () => apiClient.JSON.get("rounds.json"),
		fetch_past_year: (params: any) => apiClient.JSON.get(`archive/${params.year}/rounds.json`),
	},
	checksums: {
		fetch: () => apiClient.JSON.get("checksums.json"),
	},
	squads: {
		fetch: () => apiClient.JSON.get("squads.json"),
	},
	venues: {
		fetch: () => apiClient.JSON.get("venues.json"),
	},
	Auth: {
		auth_code_aflid: (param: TAFLiDAuthCode) => 
			apiClient.Public.post(`${AFL_ID_API_PATH}aflaccount/auth_code`, param),
		auth_login_sso: (param: TAFLiDLogin) => 
			apiClient.Public.post(`${AFL_ID_API_PATH}auth/login`, param),
		auth_email_check: (param: TAFLiDLogin) => 
			apiClient.Public.get(`${AFL_ID_API_PATH}aflaccount/email_check`, param),	
		auth_link_account: (param: TAFLiDLinkAccount) => 
			apiClient.Public.post(`${AFL_ID_API_PATH}aflaccount/link_account`, param),
		check_credentials: (param: TAFLiDCheckCredentials) => 
			apiClient.Public.post(`${AFL_ID_API_PATH}aflaccount/check_credentials`, param),
		auth_aflid_logout: (param: TLogoutParams ) => 
			aflIDClient.Public.get(`/logout${getLogoutParams(param.id_token)}`),
		link_account_login: (param: TAFLiDCheckCredentials) => 
			apiClient.Private.post("user/link_account_login", param),
		auth_link_new_account: (param: TLinkNewAccount)=>  
			apiClient.Private.post("user/link_account", param),
		login: (param: any) => apiClient.Public.post("auth/login", param),
		login_token: (param: any) => apiClient.Public.post("auth/verify_token", param),
		login_apple: (param: any) => apiClient.Public.post("appleid/auth_code", param),
		logout: () => apiClient.Private.get("auth/logout"),
		forgot_password: (param: any) => apiClient.Public.get("auth/forgot_password", param),
		change_password: (param: any) => apiClient.Public.get("auth/change_password", param),
		facebook_connect: (param: any) => apiClient.Public.post("user/facebook_connect", param),
		preregistration: (param: any) => apiClient.Public.post("preregistration", param),
	},
	User: {
		get: () => apiClient.Private.get("user"),
		check_email: (param: any) => apiClient.Private.get("user/check_email", param),
		create_user: (param: any) => apiClient.Private.post_multipart("user/create", param),
		aflid_create_user: (param: TCreateUser) =>
			apiClient.Private.post_multipart("user/create_afl_id", param),
		update_user: (param: any) => apiClient.Private.post_multipart("user/update", param),
		activate_user: (param: any) => apiClient.Private.post("user/activate_afl_id", param),
		subscribe_user: (param: any) => apiClient.Private.post("payment/subscribe", param),
		unsubscribe_user: (param: any) => apiClient.Private.get("payment/unsubscribe", param),
		subscription: (param: any) => apiClient.Private.get("payment/subscription", param),
		deactivate: (param: any) => apiClient.Private.get("user/deactivate", param),
		all_seasons_history: (param: any) => apiClient.Private.get(
			"user/all_seasons_history", param
		),
		uf_email_check: () => apiClient.Private.get("ultimatefooty/email_check"),
		uf_email_pw_check: (param: TUFLogin) => apiClient.Private.post(
			"ultimatefooty/login", {...param}
		),
		uf_link_account: (param: TUFLogin) => apiClient.Private.post(
			"ultimatefooty/link_account", {...param}
		)
	},
	leaguesClassic: {
		create: (param: any) => 
			apiClassicClient.Private.post_multipart("leagues_classic/create", param),
		show: (param: any) => apiClassicClient.Private.get("leagues_classic/show", param),
		show_teams: (param: any) => 
			apiClassicClient.Private.get("leagues_classic/show_teams", param),
		show_my: (param: any) => apiClassicClient.Private.get("leagues_classic/show_my", param),
		show_to_join: (params: any) =>
			apiClassicClient.Private.get("leagues_classic/show_to_join", params),
		joinTo: (param: any) => apiClassicClient.Private.get("leagues_classic/join_to", param),
		inviteTo: (param: any) => apiClassicClient.Private.post("leagues_classic/invite_to", param),
		ladder: (param: any) => apiClassicClient.Private.get("leagues_classic/ladder_only", param),
		update: (param: any) => 
			apiClassicClient.Private.post_multipart("leagues_classic/update", param),
		leave: (param: any) => apiClassicClient.Private.get("leagues_classic/leave", param),
		myList: (param: any) => apiClassicClient.Private.get("leagues_classic/my_list", param),
		remove: (param: any) => apiClassicClient.Private.get("leagues_classic/remove", param),
		remove_team: (param: any) => 
			apiClassicClient.Private.get("leagues_classic/remove_team", param),
		rosters: (param: any) => apiClassicClient.Private.get("leagues_classic/rosters", param),
		regenerate_list: (param: any) =>
			apiClassicClient.Private.get("leagues_classic/regenerate_list", param),
		regenerate_show: (param: any) =>
			apiClassicClient.Private.get("leagues_classic/regenerate_show", param),
		add_celebrity_teams: (param: any) => 
			apiClassicClient.Private.get("leagues_classic/add_celebrity_teams", param),
	},
	coachesBox: {
		get_settings: (param: any) => apiClassicClient.Private.get("user/coaches_box", param),
		remove_box: (param: any) => 
			apiClassicClient.Private.get("user/remove_from_coaches_box", param),
		add_box: (param: any) => apiClassicClient.Private.get("user/add_to_coaches_box", param),
		create_message: (param: any) => apiClassicClient.Private.post("coaches_box/create", param),
		get_messages: (param: any) => apiClassicClient.Private.get("coaches_box/list", param),
	},
	teamsClassic: teamsClassic.createAPIEndpoints(apiClassicClient),
	contactUs: (param: any) => apiClient.Private.post("contact", param),
	News: {
		get: () => apiClient.JSON.get("news_fantasy.json")
	},
	teamsDraftInternal: {
		update_team_name: (params: TDraftTeamNamePayload) => {
			return apiClient.Private.post("teams_draft/update_team_name", params);
		}
	}
};

export default Api;